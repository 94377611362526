import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import AccordionItem from "../components/accordion-item";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import PortfolioFeed from "../components/portfolio-feed";
import Quote from "../components/quote";
import backgroundImage from "../images/backrounds/user-interface-experience.png";

const leadingCompanies = [
  <StaticImage src="../images/clients/sundance.png" alt="Sundance Logo" />,
  <StaticImage src="../images/clients/outdoor.png" alt="Outdoor Logo" />,
  <StaticImage src="../images/clients/eridan.png" alt="Eridan Logo" />,
  <StaticImage src="../images/clients/new-tech.png" alt="New Tech Logo" />,
];

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="User Experience Design Consultants - UI UX Designer"
        description="Our user experience consultants are the kind of talented UI/UX designers who transform websites and help you beat the competition through individuality."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="hero-title bg-primary-transparent ">
            <h1 className="text-white">UI/UX consulting services</h1>
            <p className="text-white">
              At 729 Solutions, our seasoned user experience design consultants
              develop user-centered designs and strategies for digital product
              design, whether our clients need a website redesign, a new mobile
              app, or custom software solution.
            </p>
            <CTA
              href="/contact-us/"
              text="I need user-centered design"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center m-auto">
                User-centered problem solving through data-driven design
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container text-white py-6">
          <div className="pt-2">
            <div className="row">
              <div className="col">
                <div style={{ maxWidth: "100px" }}>
                  <StaticImage src="../icons/archer.png" alt="Archer" />
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">
                <h2 className="text-white">Find your secret weapon</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <p>
                  The hidden element behind some of the world’s most successful
                  brands, like Apple and Amazon, is a superior user interface
                  and user experience (UI/UX) design. Data-driven, user-centered
                  design is at the heart of great user experiences.
                </p>
              </div>
              <div className="col-lg-4">
                <ul className="text-primary m-0 pl-3">
                  <li>
                    <span className="text-white">
                      Deliver interfaces that are more intuitive for your users
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Move prospects through the buyer’s journey faster
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      User-centered design saves time and money in the long run
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <p>
                  If you would like to know more about how data-driven design
                  can be a competitive advantage and how to combine it with a
                  user-centric design, please{" "}
                  <a href="/contact-us/" className="text-primary-light">get in touch</a>.
                </p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div className="row">
              <div className="col">
                <div style={{ maxWidth: "100px" }}>
                  <StaticImage src="../icons/unicorn.png" alt="Archer" />
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">
                <h2 className="text-white">outshine your competitors</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <p>
                  The internet is a competitive place. Beat the competition
                  online by giving your customers a one-of-a-kind experience.
                </p>
                <p>
                  We help you bring innovative products and services to market
                  through collaborative and agile methodology.
                </p>
              </div>
              <div className="col-lg-4">
                <ul className="text-primary m-0 pl-3">
                  <li>
                    <span className="text-white">
                      Don’t just stay up with the times, get ahead of them
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Vastly improve the customer experience
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Reduce operational costs and increase business efficiency
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <CTA
                  text="I want to solve my user's problems"
                  className="btn btn-primary"
                  href="/contact-us/"
                />
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div className="row">
              <div className="col">
                <div style={{ maxWidth: "100px" }}>
                  <StaticImage
                    src="../icons/customer_decision.png"
                    alt="Archer"
                  />
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">
                <h2 className="text-white">
                  {" "}
                  Want a better product, faster and cheaper?
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <h4 className="text-secondary-light">
                  Research-backed designs
                </h4>
                <p>
                  There are no surprises. The solutions we design work because
                  they’re based on user testing of your real-world users and a
                  custom UX strategy. Your solution will be completely unique to
                  your company and custom-crafted with the help of innovative
                  and experienced UX design consultants.
                </p>
              </div>
              <div className="col-lg-4">
                <h4 className="text-secondary-light">
                  All-in-one digital partner
                </h4>
                <p>
                  <b>
                    We connect all the dots to solve your biggest business
                    challenges.
                  </b>{" "}
                  729 Solutions is an all-in-one digital transformation company
                  that can tackle any task you throw at us. More leads? More
                  conversions? Better return on your online investment? Whatever
                  your goal is, we’re ready to achieve it.
                </p>
              </div>
              <div className="col-lg-4">
                <h4 className="text-secondary-light">say goodbye to bloat</h4>
                <p>
                  729 Solutions is on a mission to end the bloated timelines and
                  budgets often found in this industry. Too many extra things
                  will cause your project to be over-engineered, inflating your
                  budget and extending your timelines, all for a solution that
                  performs poorly. We have proven time and again that we deliver
                  effectively and with speed, and our UI/UX consultants are
                  critical to that success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center">
                What our clients say about our user experience consultants
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <Quote
                quote="729 took the time to understand more than just our UI/UX concerns - they explored our business goals as well. This has allowed us to make decisions that consider the larger, long term vision, in addition to solving the immediate needs we brought to the table."
                name="Kate Benya"
                company="Director of Marketing, Sundance Institute"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                quote="They do a good job of setting the right expectations. The fact that we are coming back time and again is a testimony!!"
                name="Stuart Harris"
                company="Alta FoodCraft"
              />
            </div>
            <div className="col-12 col-lg-4">
              <Quote
                quote="729 allowed us the flexibility to self-implement, but were with us every step of the way. They answered questions, brainstormed, provided solutions and best practices for anything we needed."
                name="Kayla Fischer"
                company="Hirevue"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container text-white py-6">
          <div>
            <div className="row">
              <div className="col-lg-6">
                <h2 className="text-white">We can help you</h2>
              </div>
              <div className="col-lg-6">
                <ul className="text-primary m-0 pl-3 mb-4">
                  <li>
                    <span className="text-white">
                      Create and validate new ideas
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Deliver great quality products and services, fast
                    </span>
                  </li>
                  <li>
                    <span className="text-white">Be bold with technology</span>
                  </li>
                  <li>
                    <span className="text-white">Be more customer-centric</span>
                  </li>
                  <li>
                    <span className="text-white">
                      Increase conversion rates
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Improve operational efficiency
                    </span>
                  </li>
                  <li>
                    <span className="text-white">
                      Build capability and confidence
                    </span>
                  </li>
                </ul>
                <CTA
                  text="Tell us what we can do for you"
                  href="/contact-us/"
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center">Trusted by leading companies</h2>
            </div>
          </div>
          <div className="row">
            {leadingCompanies.map((component) => (
              <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center">
                <div className="row">
                  <div className="col-10 col-lg-8 m-auto py-4">{component}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <PortfolioFeed />
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-unset">Curious About UI/UX? Here Are Some FAQs.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="accordion-729">
                <AccordionItem
                  id="0"
                  checked
                  title="What does a UX consultant do?"
                  text={[
                    <p>
                      UI/UX consulting is about understanding not only our
                      customers’ needs, but their customers’ needs as well. It’s
                      about finding a balance and making sure that everyone has
                      completed their goals at the end of the day.
                      <br></br>
                      <br></br>
                      <Link to="/what-is-user-experience-design/">
                        Learn more about User Experience Design
                      </Link>
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="1"
                  title="What does a UI consultant do?"
                  text={[
                    <p>
                      A UI consultant works on the interface side of your
                      project, viewing your application through the lens of the
                      user and making User Interface (UI) design suggestions to
                      improve the user experience from end to end on your site.
                      <br></br>
                      <br></br>
                      As your UI consultant, we take the knowledge gained from
                      our User Experience (UX) team’s research and use it to
                      make informed design decisions— things like what colors
                      your users will respond to, what icons and graphic styles
                      they'll expect, and where (and in what order) to put
                      things on the page.
                      <br></br>
                      <br></br>
                      <Link to="/what-is-user-interface-design/">
                        Learn more about User Interface Design
                      </Link>
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="2"
                  title="What is a UX Agency?"
                  text={[
                    <p>
                      A UX consulting company looks at the big picture of your
                      project and your user experience, and works in a strategic
                      partnership with you to improve it on all fronts.
                    </p>,
                  ]}
                />
                <AccordionItem
                  id="3"
                  title="Why should I hire a user experience Consultant?"
                  text={[
                    <p>
                      UI/UX consulting takes the guesswork out of the design
                      process. It tells you the why behind the design and helps
                      you make decisions that are based on UX research and
                      knowledge gleaned from relevant sources.
                    </p>,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <div className="text-center">
                <h2 className="text-white mb-1">
                  Want to speak with us about your UI/UX project?
                </h2>
                <p className="text-white">
                  Want to speak with us about your UI/UX project? We love a
                  challenge. bring us your toughest problems and we’ll help you
                  sort out the pieces!!
                </p>
                <CTA
                  href="/contact-us/"
                  text="Help me sort out my UI/UX Project"
                  className="btn btn-secondary-light"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <BlogFeed title="Read more about ui design and ux experience" containerClassname="my-6" />
    </Layout>
  );
};

export default Index;
